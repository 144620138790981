module.exports = {
  authBan: true,
  synchroPatient: true,
  synchroStocks: true,
  addProductByScan: true,
  addProductManually: true,
  stockHandler: true,
  support: true,
  interventions: true,
  contacts: true,
  planification: {
    global: true,
    onInterventionAchieved: true,
    onContractCreation: true,
    actionButtons: true
  },
  baseDocumentaire: true,
  productsReminder: true,
  customProtocol: true,
  interventionReport: false
};
